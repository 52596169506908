import { citrusAdTransactionCompleted } from 'analytics/gtmServerFunctions';
import urls from 'constants/urls';
import getTransactionCompleteData from 'redux/middleware/analytics/actions-to-track/order/place-order';
import { refreshSession } from 'redux/modules/sessions/refresh-session';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { pathJoin } from 'utils/general';
import history from 'utils/history';
import {
  finishedInstantCheckout,
  startedInstantCheckout,
} from './instant-checkout-progress-status';

export default () => async (dispatch: WtrDispatch, getState: () => WtrState) => {
  try {
    const state = getState();
    const amendingOrderId = getCustomerOrderId(state);

    dispatch(startedInstantCheckout());

    const { placeAndUpdateInstantOrder } = await import(
      'redux/modules/instant-checkout/actions/place-and-update-instant-order'
    );

    if (await dispatch(placeAndUpdateInstantOrder())) {
      // send analytics to citrusAd
      const transactionCompleteData = getTransactionCompleteData(getState());
      await Promise.all([
        citrusAdTransactionCompleted(transactionCompleteData),
        dispatch(refreshSession).customerDataLoaded,
      ]);

      history.push(pathJoin(urls.orderConfirmation, amendingOrderId));
    }
  } finally {
    dispatch(finishedInstantCheckout());
  }
};
